import Gen from '@helper/Gen';
import Config from './../config/Config';

const BOGen = {
  user(){
    return Gen.getStorage('user')
  },
  notLogin(){
    return !this.user() || !Gen.getStorage('botk')
  },
  apirest(opt={}){
    if(typeof arguments[0] != "object"){
      opt = {url:arguments[0],data:arguments[1],success:arguments[2],type:arguments[3]||'get'};
    }
    if(opt.url.indexOf('http')==-1) opt.url = Config.apiBoUrl+opt.url
    var addQuery = Object.assign({
      token: Gen.getStorage("botk")
    },Gen.queryToObject(opt.url))
    opt.type = opt.type.toUpperCase()
    if(opt.type=="GET"){
      opt.data = Object.assign(addQuery, opt.data)
    }else{
      opt.url = opt.url.split("?")[0]+"?"+Gen.objectToQuery(addQuery)
      opt.data = JSON.stringify(opt.data)
      opt.contentType = "application/json; charset=utf-8"
      opt.dataType = "json"
    }
    opt.success = (resp)=>{ arguments[2](null, resp) }
    opt.error = (err)=>{ 
      err.resp = err.responseJSON;
      arguments[2](err, null)
    }
    return $.ajax(opt)
  }
}

export default BOGen