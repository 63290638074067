import Gen from "@helper/Gen"
var fo_router = [
    {
        name: "Index",
        path: "",
        component: () => import("./frontend/Index.vue"),
    },
    {
        name: "About",
        path: "about-hilo",
        component: () => import("./frontend/About.vue"),
    },
    {
        name: "Product",
        path: "products",
        component: () => import("./frontend/Product.vue"),
    },
    {
        name: "ProductCat",
        path: "products/:cat",
        component: () => import("./frontend/ProductCat.vue"),
    },
    {
        name: "ProductDetail",
        path: "products/:cat/:slug",
        component: () => import("./frontend/ProductDetail.vue"),
    },
    {
        name: "ContactUs",
        path: "contact-us",
        component: () => import("./frontend/Contact.vue"),
    },
    {
        name: "HealthInfo",
        path: "health-info",
        component: () => import("./frontend/HealthInfo.vue"),
    },
    {
        name: "HealthInfoCat",
        path: "health-info/:cat",
        component: () => import("./frontend/HealthInfoCat.vue"),
    },
    {
        name: "HealthInfoDetail",
        path: "health-info/:cat/:slug",
        component: () => import("./frontend/HealthInfoDeatil.vue"),
    },
    {
        name: "NewsEvent",
        path: "news-event",
        component: () => import("./frontend/NewsEvent.vue"),
    },
    {
        name: "NewsEventDetail",
        path: "news-event/:slug",
        component: () => import("./frontend/NewsEventDetail.vue"),
    },
    {
        name: "Toc",
        path: "term-and-condition",
        component: () => import("./frontend/Toc.vue"),
    },
    {
        name: "Privacy",
        path: "privacy-policy",
        component: () => import("./frontend/Privacy.vue"),
    },
    {
        name: "Search",
        path: "search-result",
        component: () => import("./frontend/Search.vue"),
    },
    {
        name: "404",
        path: "*",
        component: () => import("./frontend/Page404.vue"),
    }
]
fo_router.map((v) => {
	v.beforeEnter = (to, from, next) => {
		if (typeof $ != 'undefined') $("html,body").scrollTop(0)
		next()
	}
})
var data = [{
		name: "logout",
		path: "logout",
		beforeEnter(from, to, next) {
			Gen.delCookie("fotk")
			Gen.delStorage("fotk")
			if (typeof App != "undefined") App.user = null
			next({
				name: 'Home'
			})
		},
	},
	{
		path: "/",
		component: () => import('@/layout/FoLayout.vue'),
		children: fo_router
	},
]
export default data