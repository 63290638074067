import Gen from '@helper/Gen';
import BOGen from './helper/BOGen';

var bo_router = [
    {
        path: "dashboard",
        name: "BoDashboard",
        component: () =>
            import ("@backend/Dashboard.vue")
    },
    {
        path: "home/:id?",
        name: "BoHome",
        component: () =>
            import("@backend/BoHome.vue")
    },
    {
        path: "about/:id?",
        name: "BoAbout",
        component: () =>
            import("@backend/BoAbout.vue")
    },
    {
        path: "main-page/:id?",
        name: "BoProductCat",
        component: () =>
            import("@backend/BoProductCat.vue")
    },
    {
        path: "product/:id?",
        name: "BoProduct",
        component: () =>
            import("@backend/BoProduct.vue")
    },
    {
        path: "news-event/:id?",
        name: "BoNews",
        component: () =>
            import("@backend/BoNews.vue")
    },
    {
        path: "category/:id?",
        name: "BoInfoCat",
        component: () =>
            import("@backend/BoInfoCat.vue")
    },
    {
        path: "article/:id?",
        name: "BoInfo",
        component: () =>
            import("@backend/BoInfo.vue")
    },
    {
        path: "contact/:id?",
        name: "BoContact",
        component: () =>
            import("@backend/BoContact.vue")
    },
    {
        path: "seo/:id?",
        name: "StaticSeo",
        component: () =>
            import("@backend/BoStaticSeo.vue")
    },
    {
        path: "legal-page/:id?",
        name: "Legal",
        component: () =>
            import("@backend/BoLegal.vue")
    },
    {
        path: "web-settings/:id?",
        name: "WebSettings",
        component: () =>
            import("@backend/BoWebSettings.vue")
    },
    {
        path: "quiz/:id?",
        name: "BoQuiz",
        component: () =>
            import("@backend/BoQuiz.vue")
    },
    {
        path: "master-template/:id?",
        name: "MrTemplate",
        component: () =>
            import("@backend/MrTemplate.vue")
    },
    {
        path: "profile/:id?",
        name: "BoProfile",
        component: () =>
            import ("@backend/Profile.vue")
    },
    {
        path: "users/:id?",
        name: "BoUsers",
        component: () =>
            import ("@backend/Users.vue")
    },
    {
        path: "user-level/:id?",
        name: "BoUserLevel",
        component: () =>
            import ("@backend/UserLevel.vue")
    },
    // 404
    { path: '*', redirect: { name: "BoDashboard" } },
];

var menuBypassRole = ["BoProfile"]

bo_router.map((v) => {
    v.beforeEnter = (to, from, next) => {
        var user = BOGen.user()
        if (!user || !Gen.getStorage('botk')) return next({ name: "BoLogin" })
        if (menuBypassRole.indexOf(to.name) > -1) return next()
        if (user.bu_level == 0) return next()
        if (user.menuRole.indexOf(to.name) == -1) return next({ name: user.menuRole[0] })
        next()
    }
    v.meta = { bo_protected: true }
})

var data = [{
        name: "BoLogin",
        path: "login",
        component: () =>
            import ("@backend/Login.vue"),
        beforeEnter: (to, from, next) => {
            var user = BOGen.user()
            if (user && Gen.getStorage('botk')) return next({ name: "BoDashboard" })
            next()
        }
    },
    {
        name: "BoPassRecover",
        path: "password-recovery/:code?",
        component: () => 
            import ("@backend/BoPassRecover.vue")
    },
    // FileManager
    {
        name: "FileManager",
        path: 'file-explore',
        component: () =>
            import ("@components/FileManagerPage.vue")
    },
    {
        path: "",
        component: () =>
            import ('@/layout/BoLayout.vue'),
        redirect: { name: 'BoDashboard' },
        children: bo_router
    },
]

export default data